<template>
<div>
  <b-table
  :items="translatedLabs"
  :fields="fields"
  hover
  tbody-tr-class="clickable"
  :busy="isLoading"
  thead-class="hide"
  @row-clicked="goToLab"
  v-if="labs.length > 0"
  >

    <template #table-busy>
      <loading :loading="true"></loading>
    </template>

    <template #table-colgroup="scope">
      <col
        v-for="field in scope.fields"
        :key="field.key"
        :style="{ width: field.key === 'status' ? '30%' : '70%' }"
      >
    </template>

    <!-- A custom formatted column -->
    <template #cell(name)="data">
      <div class="ellipsis">
        <span class="body-2 ml-3">{{ data.value }}</span><br>
        <span class="description paragraph ml-3">{{ data.item.description | striphtml }}</span>
      </div>
    </template>

    <!-- A custom formatted column -->
    <template #cell(status)="data">
      <div class="text-right mr-3">
        <StatusPill :status="data.value" />
      </div>
    </template>

  </b-table>

  <div v-else class="pl-3">
    <p>{{ $t('LABS.no_recent_labs') }}</p>
  </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import StatusPill from '@/components/utils/pills/StatusPill'
import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'recent-labs-table',
  mixins: [i18nmixin],
  components: { StatusPill },
  data () {
    return {
      fields: [
        // A column that needs custom formatting
        { key: 'name', label: this.$t('LABS.name') },
        { key: 'status', label: this.$t('LABS.status') }
      ]
    }
  },
  methods: {
    ...mapActions({
      getRecentsLabs: 'labs/getRecentLabs'
    }),
    goToLab (lab) {
      this.$router.push({ name: 'labDetail', params: { id: lab.id } })
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'labs/isLoading',
      labs: 'labs/getRecentLabs'
    }),
    translatedLabs () { return this.translateObjectArray(this.labs) }
  },
  mounted () {
    this.getRecentsLabs()
  }

}
</script>

<style>

</style>
