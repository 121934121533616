<template>
  <div class="home pt-5">
    <loading :loading="isLoading"></loading>

    <div id="home-container" class="main-container">

      <b-row class="justify-content-center" align-v="stretch">
        <b-col xs="12" md="10" class="mb-3">
          <b-alert show dismissible variant="primary" v-for="announcement in translateObjectArray(announcements)" v-bind:key="announcement.title">
            <div class="flex">
              <div class="mr-3" v-if="announcement.image">
                <img class="announcement-img" :src="getUrlImg(announcement.image)" alt="">
              </div>
              <div>
                <span class="announcement-title font-weight-bold">{{ announcement.title }}</span><br>
                <span>{{ announcement.description }}</span>
              </div>
            </div>
          </b-alert>
        </b-col>
        <b-col xs="12" md="10" class="mb-3">
          <UserInfo/>
        </b-col>
        <b-col md="8" sm="12" class="mb-3" v-if="canSeeCareers">
          <Card no-shadow>
            <template v-slot:title>
              <div class="pb-3">
                <span class="subtitle">{{ $t('CAREERS.summary') }}</span>
              </div>
            </template>

            <template v-slot:content>
              <b-row v-if="careers.length > 0">
                <b-col
                v-for="n in careers"
                v-bind:key="n.id"
                xl="3"
                lg="4"
                md="6"
                sm="12"
                class="mb-3">
                  <RadialProgressStatCard :id="n.id" :name="n.name" :points="n.score" :total="n.total_points" />
                </b-col>
              </b-row>
              <div v-else>
                <p>{{ $t('PILLS.no_pills_yet') }}</p>
              </div>
            </template>
          </Card>
        </b-col>

        <b-col md="2" sm="12" class="mb-3" v-if="canSeeCareers">
          <router-link :to="{ name: 'careers' }">
            <Card no-padding no-shadow class="card-background bg3 px-3 pt-4 pb-1 mb-3">
              <template v-slot:content>
                <div class="content-wrapper">
                  <span class="subtitle">{{ $t('TERMS.careers') }}</span>
                  <div class="text-left mt-4 mb-4">
                    <b-icon icon="arrow-right" variant="white" font-scale="2" />
                  </div>
                </div>
              </template>
            </Card>
          </router-link>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col md="4" sm="12" class="mb-3">
          <Card no-padding no-shadow>
            <template v-slot:title>
              <div class=" pt-4 pl-3 pb-2">
                <span class="subtitle">{{ $t('PILLS.recent') }}</span>
              </div>
            </template>

            <template v-slot:content>
              <RecentPills></RecentPills>
            </template>
          </Card>
        </b-col>

        <b-col md="2" sm="12" class="mb-3">
          <b-row align-v="center">
            <b-col>
              <router-link :to="{ name: 'categories', query: { show: 'pills' } }">
                <Card no-padding no-shadow class="card-background bg2 px-3 pt-4  mb-3">
                  <template v-slot:content>
                    <div class="content-wrapper">
                      <span class="subtitle">{{ $t('TERMS.pills') }}</span>
                      <div class="text-left mt-4">
                        <b-icon icon="arrow-right" variant="white" font-scale="2" />
                      </div>
                    </div>
                  </template>
                </Card>
              </router-link>
              <router-link :to="{ name: 'labs' }">
                <Card no-padding no-shadow class="card-background bg1 px-3 pt-4 pb-1">
                  <template v-slot:content>
                    <div class="content-wrapper">
                      <span class="subtitle">{{ $t('TERMS.labs_full') }}</span>
                      <div class="text-left mt-4">
                        <b-icon icon="arrow-right" variant="white" font-scale="2" />
                      </div>
                    </div>
                  </template>
                </Card>
              </router-link>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4" xs="12" class="mb-3">
          <Card no-padding no-shadow>
            <template v-slot:title>
              <div class=" pt-4 pl-3 pb-2">
                <span class="subtitle">{{ $t('LABS.recent') }}</span>
              </div>
            </template>

            <template v-slot:content>
              <RecentLabs></RecentLabs>
            </template>
          </Card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RadialProgressStatCard from '@/components/utils/cards/RadialProgressStatCard'

import RecentLabs from '@/components/tables/RecentLabs'
import RecentPills from '@/components/tables/RecentPills'

import UserInfo from '@/components/home/UserInfo'

import i18nmixin from '@/services/mixins/i18n.mixins'
import mapper from '@/services/mixins/mapper.mixins'

export default {
  name: 'Home',
  mixins: [i18nmixin, mapper],
  components: { RecentLabs, RecentPills, UserInfo, RadialProgressStatCard },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions({
      getAnnouncements: 'staticInfo/getAnnouncements',
      getHomeStats: 'staticInfo/getHomeStats'
    })
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      announcements: 'staticInfo/getAnnouncements',
      isLoadingAnnounce: 'staticInfo/isLoading',
      isLoadingCareers: 'careers/isLoading',
      isLoadingPills: 'pills/isLoading',
      homeStats: 'staticInfo/getHomeStats',
      canSeeCareers: 'config/canSeeCareers'
    }),
    careers () { return this.homeStats.categories_nice },
    isLoading () { return this.isLoadingAnnounce || this.isLoadingCareers || this.isLoadingPills }
  },
  mounted () {
    this.getAnnouncements()
    this.getHomeStats()
  }
}
</script>

<style lang="scss" scoped>

  .announcement-img {
    height: 48px;
    width: 100%;
  }

  #home-container .row {
    margin-left: unset;
    margin-right: unset;
  }
  a:hover {
    text-decoration: none;
  }

  .card-background {
    min-height: 125px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .content-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &.bg1 {
      color: white;
      background-color: #160D5B;
      background-image: url('/media/img/labs.jpg');
      background-size: 60%;
      background-position: 115% 120%;
    }

    &.bg2 {
      color: white;
      background-color: $yellow;
      background-image: url('/media/img/pill_learning.png');
      background-size: 40%;
      background-position: 90% center;
    }

    &.bg3 {
      background-color: $light-cobalt;
      background-image: url('/media/img/career_arrow_up.png');
      background-size: 75%;
      background-position: 175% 100%;
      color: white;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .card-background {
      &.bg1 {
        background-position: 115% 30%;
      }

      &.bg3 {
        background-position: 175% 30%;
      }
    }
  }
</style>
