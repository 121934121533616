<template>
<div>
  <b-table
  :items="translatedPills"
  :fields="fields"
  hover
  tbody-tr-class="clickable"
  :busy="isLoading"
  thead-class="hide"
  @row-clicked="goToPill"
  v-if="pills.length > 0"
  >

    <template #table-busy>
      <loading :loading="true"></loading>
    </template>

    <template #table-colgroup="scope">
      <col
        v-for="field in scope.fields"
        :key="field.key"
        :style="{ width: field.key === 'status' ? '30%' : '70%' }"
      >
    </template>

    <!-- A custom formatted column -->
    <template #cell(name)="data">
      <div class="ellipsis">
        <span class="body-2 ml-3">{{ data.value }}</span><br>
        <span class="description paragraph ml-3">{{ data.item.description | striphtml }}</span>
      </div>

    </template>

    <!-- A custom formatted column -->
    <template #cell(student_pill_completion.completion_status)="data">
      <div class="text-right mr-3">
        <StatusPill :status="data.value" />
      </div>
    </template>

  </b-table>

  <div v-else class="pl-3">
    <p>{{ $t('PILLS.no_recent_pills') }}</p>
  </div>
</div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import StatusPill from '@/components/utils/pills/StatusPill'
import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'recent-pills-table',
  mixins: [i18nmixin],
  components: { StatusPill },
  data () {
    return {
      fields: [
        // A column that needs custom formatting
        { key: 'name', label: this.$t('PILLS.name') },
        { key: 'student_pill_completion.completion_status', label: this.$t('PILLS.status') }
      ]
    }
  },
  methods: {
    ...mapActions({
      getRecentPills: 'pills/getRecentsPills'
    }),
    goToPill (pill) {
      this.$router.push({ name: 'pillDetail', params: { id: pill.id } })
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'pills/isLoading',
      pills: 'pills/getRecentsPills'
    }),
    translatedPills () { return this.translateObjectArray(this.pills) }
  },
  mounted () {
    this.getRecentPills()
  }
}
</script>
