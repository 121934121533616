<template>
  <Card :no-shadow="true" class="text-center">
    <template v-slot:title>
      <router-link :to="{ name: 'careerDetail', params: { id: id } }" class="inline-wrapper no-style">
        <SemiCircleChart :progress="progress" :current="points" :total="total" />
        <span class="font-weight-semibold">
          <span class="body-2">{{ name }}</span>
        </span>
      </router-link>
    </template>
  </Card>
</template>

<script>
import SemiCircleChart from '@/components/utils/SemiCircleChart'
import ProgressMixin from '@/services/mixins/progress.mixins'

export default {
  mixins: [ProgressMixin],
  components: { SemiCircleChart },
  props: {
    id: {
      type: Number, required: true
    },
    name: {
      type: String, required: false, default: ''
    },
    points: {
      required: false, default: 0
    },
    total: {
      required: false, default: 0
    }
  },
  computed: {
    progress () { return this.calculateProgress(this.points, this.total) }
  }
}
</script>
