<template>
<div class="w100p">
  <apexchart
    class="card-rounded-bottom"
    :options="chartOptions"
    :series="series"
    type="radialBar"
  ></apexchart>
  <div style="margin-top: -64px;" v-if="total">
    {{ current }}<span class="max">/{{ total }}</span>
  </div>
</div>

</template>

<script>
export default {
  props: {
    progress: { required: true },
    current: { required: false, default: 0 },
    total: { required: false }
  },
  data () {
    return {
      chartOptions: {}
    }
  },
  computed: {
    series () {
      return [this.progress]
    }
  },
  mounted () {
    this.chartOptions = {
      colors: ['var(--primary)'],
      chart: {
        height: 200,
        type: 'radialBar'
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 0,
            size: '70%'
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
              fontWeight: '500'
            },
            value: {
              // color: this.layoutConfig('colors.gray.gray-700'),
              fontSize: '30px',
              fontWeight: '500',
              offsetY: -12,
              show: true
            }
          },
          track: {
            // background: this.layoutConfig('colors.theme.light.success'),
            strokeWidth: '100%'
          }
        }
      },
      // colors: [this.layoutConfig('colors.theme.base.success')],
      stroke: {
        background: '#123123',
        lineCap: 'round'
      },
      labels: ['Progress']
    }
  }

}
</script>

<style lang="scss" scoped>

.max {
  font-size: 0.8rem;
  color: $grey-medium;
}
</style>
