<template>
  <Card
    no-shadow
    no-hover
    :disabled="disabled"
    :class="{ disabled: disabled }"
    :to="to">
    <template v-slot:title>
      <span class="subtitle label">{{ label  }}</span>

      <div class="icon-wrapper">
        <b-icon :icon="icon" />
      </div>
    </template>
    <template v-slot:content>
      <div v-if="!disabled" v-b-tooltip.hover.bottom :title="tooltip">
        <span class="value">{{ value }}</span>
        <span class="max" v-if="max">/{{ max }}</span>
      </div>
      <div v-else>
        <span style="font-size: 30px; opacity: 0;">&#x200B;</span>{{ disabledMsgComputed }}
      </div>
    </template>
  </Card>
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true },
    type: { type: String, required: false, default: 'number' },
    disabled: { type: Boolean, required: false, default: false },
    disabledMsg: { type: String, required: false, default: '' },
    value: {
      required: true
    },
    max: {
      type: Number, required: false, default: 100
    },
    label: {
      required: true
    },
    to: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
    tooltip: { type: String, required: false, default: null }
  },
  methods: {
    goToDestination () {
      if (Object.keys(this.to).length === 0) return

      this.$router.push(this.to)
    }
  },
  computed: {
    disabledMsgComputed () {
      return this.disabledMsg || '1234'
    }
  }

}
</script>

<style lang="scss" scoped>
.label {
  display: inline-block;
  vertical-align: top;
  width: 70%;
  margin-bottom: 16px;
}

.value {
  font-size: 30px;
  font-weight: bold;
}

.max {
  font-size: 14px;
  color: $grey-medium;
}

.icon-wrapper {
  padding: 8px 12px;
  background-color: var(--secondary);// $primary-light;
  float: right;
  border-radius: 8px;
  text-align: center;
  color: white; // $primary;
}

.card.disabled {
  &:after {
    content: '';
    border-radius: 8px;
    position: absolute;
    display: block;
    background-color: grey;
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }
}

</style>
