<template>
<div>
  <b-row>
    <b-col>
      <div class="card colored hello">
        <div class="text-wrapper">
          <h2>{{ $t('GENERAL.hello') }} {{ user.name }},</h2>
          <span class="body-2">{{ $t('HOME.greeting') }}</span>
        </div>
      </div>
    </b-col>
  </b-row>
  <b-row style="height: 50%;">
    <b-col xs="12" md="6" lg="3" class="mt-3">
      <StatCardV2 :to="{ name: 'categories', query: { show: 'pills' } }" :value="homeStats.pills_completed" :max="homeStats.pills_total" icon="book-fill" :label="$t('TERMS.pills')" :tooltip="$t('GENERAL.only_showing_available_pills')" />
    </b-col>

    <b-col xs="12" md="6" lg="3" class="mt-3">
      <StatCardV2 :to="{ name: 'labs' }" :value="homeStats.vms_completed" :max="homeStats.vms_total" icon="trophy-fill" :label="$t('TERMS.machines')"/>
    </b-col>

    <b-col xs="12" md="6" lg="3" class="mt-3">
      <StatCardV2 :to="{ name: 'niceByUser' }" :value="homeStats.nice_completed" :max="homeStats.nice_total" icon="x-diamond-fill" :disabled="!canSeeCareers" :disabledMsg="$t('HOME.careers_not_available')" :label="$t('TERMS.categories_nice')" />
    </b-col>

    <b-col xs="12" md="6" lg="3" class="mt-3">
      <StatCardV2 :to="{ name: 'mitreByUser' }" :value="homeStats.mitre_completed" :max="homeStats.mitre_total" icon="x-diamond" :label="$t('TERMS.categories_mitre')"/>
    </b-col>
  </b-row>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

import StatCardV2 from '@/components/utils/cards/StatCardV2'

export default {
  name: 'UserInfo',
  components: { StatCardV2 },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      homeStats: 'staticInfo/getHomeStats',
      canSeeCareers: 'config/canSeeCareers'
    })
  }
}
</script>

<style lang="scss" scoped>
.hello {
  color: white !important;
  height: 300px !important;
  background-image: url('/media/img/bg/background2.jpg') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.card.colored {
    background-color: var(--primary-dark);
    border: none;
    padding: 16px 128px;
    flex-direction: row;

    .text-wrapper {
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .img-wrapper {
      width: 25%;
      text-align: right;
    }

    .title {
      font-size: 2rem;
      font-weight: 500;
    }

    .concept {
      float: right;
      height: 256px;
    }
}

.card.overview {
  background: #fff4de;
  border: none;
  padding: 16px;

  .overview {
    display: block;
    width: 32px;
    height: 32px;
  }
}

@media (max-width: $mobile-breakpoint) {

  .card.colored {
    padding: 0px;
    padding-left: 10%;

    .text-wrapper {
      width: 90%;

      h2 {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
}
</style>
